<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px"/>
                <span>{{this.entity.name + ' ' + l('Mail_template_configuration')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>

                    <a-col :span="18">
                        <a-row>
                            <a-col :span="24" style="margin-left: 21px">
                                <a-col :span="2" style="text-align: right">
                                    <a-form-item :label="l('Email_Title')">

                                    </a-form-item>
                                </a-col>
                                <a-col :span="20">
                                    <a-input v-model="entity.title" class="input" style="color: black;width: 100%;"></a-input>
                                    <br>
                                    <span>示例：尊敬的{xxx}，有一条新邮件。（要带上大括号，字段名可参考下方字段配置参考）也可不带参数。每日消息提醒共用一个邮件标题</span>
                                </a-col>
                            </a-col>
                            <a-col :span="24" style="margin-left: 21px;">
                                <span style="margin-left: 10%;color: red;">如果要显示表格，请加上@table关键字(区分大小写)
                                    <!-- 如果要显示当前时间，请加上@time关键字(区分大小写)如果要显示当前场景，请加上@scene关键字(区分大小写) -->
                                </span>
                            </a-col>
                            <a-col :span="24" style="margin-left: 21px">
                                <a-col :span="2" style="text-align: right">
                                    <a-form-item :label="l('NewsContent')">
                                    </a-form-item>
                                </a-col>
                                <a-col :span="20">
                                    <editor v-model="entity.content" @onChange="editorOnChang"></editor>
                                </a-col>
                            </a-col>
<!--                            <a-col :span="24" style="margin-left: 21px">-->
<!--                                <a-col :span="3" style="text-align: right">-->
<!--                                    <a-form-item :label="l('字段配置参考')">-->

<!--                                    </a-form-item>-->
<!--                                </a-col>-->
<!--                                <a-col :span="19">-->
<!--                                    <span v-if="entity.code == 'NewProb'">{{NewProbstr}}<br></span>-->
<!--                                    <span v-if="entity.code == 'ProgressChange'">{{ProgressChangestr}}<br></span>-->
<!--                                    <span v-if="entity.code == 'TaskTurn'">{{TaskTurnstr}}<br></span>-->
<!--                                    <span v-if="entity.code == 'IssueTurn'">{{IssueTurnbstr}}<br></span>-->
<!--                                    <span v-if="entity.code == 'DailyMessageInTodayPlan'">{{DailyMessagestr1}}<br></span>-->
<!--                                    <span v-if="entity.code == 'DailyMessageInProbWillOutTime'">{{DailyMessagestr2}}<br></span>-->
<!--                                    <span v-if="entity.code == 'DailyMessageInOutTimeProb'">{{DailyMessagestr3}}<br></span>-->
<!--                                    <span v-if="entity.code == 'IssueEdit'">{{IssueEditstr}}<br></span>-->

<!--                                </a-col>-->
<!--                            </a-col>-->
                            <a-col :span="24" style="margin-left: 21px">
                                <a-col :span="3" style="text-align: right">
                                    <a-form-item :label="l('配置举例')">

                                    </a-form-item>
                                </a-col>
                                <a-col :span="19">
                                    <span>表格表头配置是文字，字段之间以逗号分割，表格内容配置填{}里面的内容(字段严格按照大小写！！！表头与内容字段数量要一致)</span><br>
                                    <!--                            <span>单个（表格表头字段配置:xx）多个（表格表头字段配置:xx,xx）</span><br>-->
                                    <!--                            <span>单个（表格内容字段配置:xx）多个（表格内容字段配置:xx,xx）</span><br>-->
                                </a-col>
                            </a-col>
                            <a-col :span="24" style="margin-left: 21px">
                                <a-col :span="3" style="text-align: right">
                                    <a-form-item :label="l('表格表头字段配置')">

                                    </a-form-item>
                                </a-col>
                                <a-col :span="19">
                                    <a-input v-model="theadstr" class="input" style="color: black;width: 100%;"></a-input>
                                </a-col>
                            </a-col>
                            <a-col :span="24" style="margin-left: 21px">
                                <a-col :span="3" style="text-align: right">
                                    <a-form-item :label="l('表格内容字段配置')">

                                    </a-form-item>
                                </a-col>
                                <a-col :span="19">
                                    <a-input v-model="tbodystr" class="input" style="color: black;width: 100%;"></a-input>
                                </a-col>
                            </a-col>
                            <a-col :span="24" style="margin-left: 21px">
                                <a-button @click="btnclick"  type="primary">查看表格效果</a-button>
                                <p v-html="tablestr" STYLE="width: 90%;"></p>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="6">
                        <a-row>
                            <a-col :span="24">
                                <a-col :span="24" style="text-align: left;">
                                    {{l("字段配置参考(点击字段可复制字段):")}}
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'NewProb'" v-for="item in NewProbstr.split(',')" :key="item">
                                    <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'ProgressChange'" v-for="item in ProgressChangestr.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'TaskTurn'" v-for="item in TaskTurnstr.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'TaskDetailTurn'" v-for="item in TaskDetailTurnstr.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'IssueTurn'" v-for="item in TaskTurnstr.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'DailyMessageInTodayPlan' || entity.code == 'DailyMessageInPlanWillOutTime' || entity.code == 'DailyMessageInOutTimePlan' || entity.code == 'NewPlan'" v-for="item in DailyMessagestr1.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'DailyMessageInProbWillOutTime'" v-for="item in DailyMessagestr2.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'DailyMessageInOutTimeProb'" v-for="item in DailyMessagestr3.split(',')" :key="item">
                                    <template >
                                        <template >
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                                <a-col :span="24" style="margin-bottom: 10px;" v-if="entity.code == 'IssueEdit'" v-for="item in IssueEditstr.split(',')" :key="item">
                                    <template >
                                        <template>
                                            <a href="#" @click="CopyText(item)">{{item}}</a>
                                        </template>
                                    </template>
                                </a-col>
                            </a-col>
                        </a-row>
                    </a-col>


                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import {MailTemplateServiceProxy,MailTemplateEditDto,CreateOrUpdateMailTemplateInput} from '../../../../shared/service-proxies';
    import Editor from "../../../../components/editor/editor";
    import VueClipboard from 'vue-clipboard2'

    let _this;
    export default {
        name: 'email',
        mixins: [ModalComponentBase],
        components: {
            Editor,
            VueClipboard
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this.MailTemplateServiceProxy = new MailTemplateServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if (this.isEdit){
                this.getData();
            }
            // this.entity.code = "NewProb";
            // this.entity.enable = true;
        },
        data() {
            return {
                zh_CN,
                id:undefined,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: new MailTemplateEditDto,
                NewProbstr:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                ProgressChangestr:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                IssueTurnbstr:"转办人{FromUserName},代办人{ToUserName},问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                TaskTurnstr:"转办人{FromUserName},代办人{ToUserName},审核人{InspectorName},计划日期{FomartDateTime},状态{AuditTaskStateName},任务名称{Name},任务类型{AuditTaskTypeName},当前时间@time,场景@scene,企业@tenant",
                TaskDetailTurnstr:"转办人{FromUserName},代办人{ToUserName},审核人{InspectorName},审核项类型{AuditItemTypeName},概述{ItemName},详细描述{ItemDescription},序号{AuditItemSN},选项类型{OptionType},标准值{ValueStandard},上限{ValueUpperLimit},下限{ValueLowerLimit},当前时间@time,场景@scene,企业@tenant",
                DailyMessagestr1:"任务类型{AuditTaskTypeName},审核模板名{AuditTemplateName},审核人{InspectorName},计划日期{FomartDateTime},实际开始日期{StartTime},实际结束日期{EndTime},状态{AuditTaskStateName},备注{Remark},任务名称{Name},提前开始{EarlyStartTime},延后完成{DelayedFinishTime},当前时间@time,场景@scene,企业@tenant",
                DailyMessagestr2:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                DailyMessagestr3:"问题来源类型{SourceTypeName},问题类型{IssueTypeName},问题进度{IssueProgressName},问题描述{Description},发现人{DiscovererName},发现时间{DiscoveryTime},责任人{RespName},审核点{AuditPointName},审核项概述{AuditItemName},计划开始处理日期{PlanStart},计划完成日期{PlanEnd},实际完成时间{CloseTime},改善建议{ImproveNote},改正措施{CorrectiveNote},备注{Remark},当前时间@time,场景@scene,企业@tenant",
                IssueEditstr:"问题修改记录较特殊，建议严格按照要求修改。{系统字段}在{系统字段}修改了{系统字段}问题。例：张三在xx天xx时xx分修改了XXX问题。只需修改不是{系统字段}的文字即可",
                theadstr:undefined,
                tbodystr:undefined,
                tablestr:undefined,
				isclick: true,
            }
        },
        methods: {

            CopyText(e) {
                let createInput = document.createElement("input");
                let regex3 = /\{(.+?)\}/g; // {} 花括号，大括号
                let ss = e.match(regex3)
                createInput.value = ss[0].replace("{","").replace("}","");
                document.body.appendChild(createInput);
                createInput.select(); // 选择对象
                document.execCommand("Copy"); // 执行浏览器复制命令
                createInput.style.display = "none";
                this.$message.success('复制成功');
            },

            cancel(e) {
                this.$message.error('取消复制');
            },

            onChange(checked) {
                console.log(`a-switch to ${checked}`);
            },


            change(value) {
                console.log(value)
                if(value == undefined){
                    this.entity.code = undefined;
                }
                else{
                    this.entity.code = value.key;
                    this.entity.name = value.label;
                }
                console.log(this.entity)
            },

            btnclick(){
                if (this.theadstr == undefined || this.theadstr ==""){
                    abp.message.info('没有输入信息，无法查看效果');
                    return;
                }
                let table = "<table border='1' style='border-collapse: collapse; width: 100%;text-align: center;'>";

                let theadstrArr = this.theadstr.split(',');
                let tbodystrArr = this.tbodystr.split(',');

                let s1 = "";
                s1 += "<thead><tr>";
                for (let i = 0; i < theadstrArr.length; i++) {
                    s1 += "<th>"+theadstrArr[i]+"</th>";
                }
                s1 += "</tr></thead>";


                let s2 = "";
                s2 += "<tbody><tr>";
                for (let i = 0; i < tbodystrArr.length; i++) {
                    s2 += "<td>"+tbodystrArr[i]+"</td>";
                }
                s2 += "</tr></tbody>";
                table += s1;
                table += s2;
                table+="</table>";
                this.tablestr = table;
                console.log(table)
                console.log(s1)
                console.log(s2)
            },

            getData() {
                this.isLoading = true;
                this.MailTemplateServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.entity = res;
                    this.theadstr = res.inputThead;
                    this.tbodystr = res.inputTbody;
                })
            },
            editorOnChang(e) {
                this.entity.content = e;
            },
            save() {

                if (this.entity.content == undefined || this.entity.content  ==""){
                    abp.message.info('请输入邮件模板内容');
                    return;
                }

                if (this.entity.title == undefined || this.entity.title  ==""){
                    abp.message.info('请输入邮件标题');
                    return;
                }

                let sum= this.entity.content.indexOf("@table");
                let table = "<table border='1' style='border-collapse: collapse; width: 100%;text-align: center;'>";

                if(sum>-1){
                    let theadstrArr = this.theadstr.split(',');
                    let tbodystrArr = this.tbodystr.split(',');
                    let s1 = "";
                    let s2 = "";
                    s1 += "<thead><tr>";
                    for (let i = 0; i < theadstrArr.length; i++) {
                        s1 += "<th>"+theadstrArr[i]+"</th>";
                    }
                    s1 += "</tr></thead>";
                    s2 += "<tbody><tr>";
                    for (let i = 0; i < tbodystrArr.length; i++) {
                        s2 += "<td>{"+tbodystrArr[i]+"}</td>";
                    }
                    s2 += "</tr></tbody>";
                    table += s1;
                    table += s2;
                    table+="</table>";
                    // this.tablestr = table;
                    this.entity.inputThead = this.theadstr;
                    this.entity.inputTbody = this.tbodystr;
                    this.entity.formatThead = s1;
                    this.entity.formatTbody = s2;
                }else{
                    this.entity.inputThead = undefined;
                    this.entity.inputTbody = undefined;
                    this.entity.formatThead = undefined;
                    this.entity.formatTbody = undefined;
                }

                console.log(this.entity)
                let CreateOrUpdateMailTemplateInput= {};
                CreateOrUpdateMailTemplateInput.mailTemplate = this.entity;
                // return;
				if(this.isclick) {
					this.isclick = false;
					this.MailTemplateServiceProxy.createOrUpdate(
					    CreateOrUpdateMailTemplateInput
					).finally(() => {
					    // this.close()
					}).then(res => {
						this.isclick = true;
					    this.success(true)
					})
				}
            },

            filterOption(input, option) {
                return (
                    option.componenupdatetOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

        }
    }
</script>

<style>
    .input {
        width: 200px;
    }
</style>

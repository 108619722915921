<template>
    <div class="edit_container">
        <quill-editor
                :content="value"
                ref="myQuillEditor"
                :options="editorOption"
                @change="onEditorChange($event)">
        </quill-editor>
        <div style="display:none;">
            <a-upload
                    name="profilePictureFile"
                    :fileList="fileList"
                    :action="uploadPictureUrl"
                    :beforeUpload="beforeUpload"
                    accept="image/*"
                    :headers="uploadHeaders"
                    @change="handleChange"
            >
                <div  ref="Upload"></div>
            </a-upload>
        </div>
    </div>

</template>

<script>
    import {addQuillTitle} from '../editor/editor-title'
    import {quillEditor} from "vue-quill-editor"; //调用编辑器
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import Quill from 'quill';
    import {AppComponentBase} from "../../shared/component-base";
    import {AppConsts} from "../../abpPro/AppConsts";
    import {AudititemServiceProxy} from "../../shared/service-proxies";

    let fontSizeStyle = Quill.import('attributors/style/size');
    fontSizeStyle.whitelist = [false, '10px', '12px', '14px', '16px', '20px', '24px', '36px'];//false表示默认值
    Quill.register(fontSizeStyle, true);
    let _this;
    export default {
        name: "editor",
        mixins:[AppComponentBase],
        components: {
            quillEditor
        },
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                editorOption: {},
                fileList:[],
                // 上传的接口地址
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/TempFileUpLoad",
                uploadHeaders:{
                    Authorization: "Bearer " + abp.auth.getToken(),
                },
                maxCompanyPictureBytesValue: AppConsts.maxCompanyPictureMb,
                imgPath:`${AppConsts.OSSRequestUrl}/File/LPA/${abp.session.tenantId}/AuditItem/`
            }
        },
        created() {
            _this=this;
            this.AudititemServiceProxy = new AudititemServiceProxy(this.$apiUrl, this.$api);
            const modules = {
                toolbar: {
                    container: [
                        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                        ['blockquote', 'code-block'],
                        [{ header: 1 }, { header: 2 }], // custom button values
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                        [{ direction: 'rtl' }], // text direction
                        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                        [{ header: [false,1, 2, 3, 4, 5, 6] }],
                        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                        [{ font: [] }],
                        [{ align: [] }],
                        ['link', 'image'],
                        ['clean'] // remove formatting button
                    ],
                    handlers: {
                        image: function(value) {
                            _this.$refs.Upload.click() //自定义图片上传回调
                        }
                    }
                }
            }
            this.editorOption.modules = modules
        },
        mounted() {
            //tip提示
            addQuillTitle();
        },
        computed: {
            editor() {
                return this.$refs.myQuillEditor.quill;
            },
        },
        methods: {
            handleChange({file,fileList}) {
                if (file.status == 'done') {
                    this.AudititemServiceProxy.moveFile(file.response.result.fileName)
                        .finally(()=>{})
                        .then(res=>{
                            //图片上传成功后返回值
                            let url =file.response.result.fileName;
                            let quill = this.$refs.myQuillEditor.quill;
                            let length = quill.getSelection().index;　//获取当前鼠标焦点位置
                            quill.insertEmbed(length, 'image', this.imgPath+url);//插入<img src='url'/>
                        });
                }
                this.fileList=fileList;
            },
            /**
             * 上传图片
             */
            beforeUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "image/gif";
                if (!isJPG) {
                    abp.message.error(this.l("OnlySupportPictureFile"));
                }
                const isLtXM = file.size / 1024 / 1024 < this.maxCompanyPictureBytesValue;
                if (!isLtXM) {
                    abp.message.error(
                        this.l(
                            "ProfilePicture_Warn_SizeLimit",
                            this.maxCompanyPictureBytesValue
                        )
                    );
                }
                return isJPG && isLtXM;
            },
            // 内容改变事件
            onEditorChange(e) {
                // this.$emit('update:value', e.html);
                //console.log(e);
                this.$emit("onChange", e.html);
            },
        }
    }
</script>

<style>
    .edit_container {
        height: 400px;
    }

    .quill-editor {
        height: 300px;
    }

    .ql-snow .ql-picker.ql-size:active {
        border: none;
    }

    .ql-snow .ql-picker.ql-size::selection {
        border: none;
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
        content: '字体大小';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
        content: '10px';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
        content: '12px';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
        content: '14px';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
        content: '16px';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
        content: '20px';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
        content: '24px';
    }

    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before, .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
        content: '36px';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before, .ql-snow .ql-picker.ql-header .ql-picker-item::before {
        content: '标题';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
        content: '一级标题';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
        content: '二级标题';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
        content: '三级标题';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
        content: '四级标题';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
        content: '五级标题';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
        content: '六级标题';
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before, .ql-snow .ql-picker.ql-font .ql-picker-item::before {
        content: '字体';
    }
</style>
